<template>
    <v-container
        fluid
    >  
        <v-row
            justify="center"
        >
            <v-col
                xs="12"
                md="8"  
            >
                <h3>{{ $t('exemption-header') }}</h3>
            </v-col>
        </v-row>  
        <v-row
            justify="center"
        >
            <v-col
                xs="12"
                md="8"
            >
                <h3 style="color: red; font-size: small;">
                    {{ $t('exemption-buffer-window', {buffer: exemptionBuffer}) }}
                </h3>
            </v-col>
        </v-row>
        <v-row
            justify="center"
        >
            <v-col
                xs="12"
                md="8"  
                class="my-0 py-0"
            >
                <h4>{{ $t('find-registration') }}</h4>
            </v-col>
        </v-row>
        <v-form
            ref="form"
            v-model="valid"
            lazy-validation
        >
            <v-row
                justify="center"
            >
                <v-col
                    xs="12"
                    md="8"  
                >
                    <v-text-field
                        ref="registrationInput"                        
                        v-model="registrationNumber"
                        tab-index="0"
                        :label="$t('common-registrationNumber')"
                        :rules="globalRegistrationNumberValidator(registrationNumber)"
                        hide-details="auto"
                        type="text" 
                        required
                        class="registrationNumber"
                        @input="registrationNumber = registrationNumber.toUpperCase()"
                    >
                        <template v-slot:append>
                            <v-btn
                                icon
                                tabindex="-1"
                                @click="clearRegistrationNumberField()"
                            >
                                <v-icon>mdi-close-circle</v-icon>
                            </v-btn>
                        </template>
                    </v-text-field>
                </v-col>
            </v-row>
            <v-row
                justify="center"
            >
                <v-col
                    xs="12"
                    md="8"  
                >
                    <v-text-field
                        ref="cvrNumberInput"
                        v-model="cvrNumber"
                        type="text" 
                        inputmode="decimal" 
                        pattern="[0-9]*" 
                        autocorrect="off"
                        class="mt-5"
                        tab-index="1"
                        :label="$t('vat')"
                        :rules="globalCvrNumberValidator(cvrNumber)"
                        hide-details="auto"
                        required                 
                    >
                        <template 
                            v-slot:append
                        >
                            <v-btn
                                icon
                                tabindex="-1"
                                @click="clearCVRNumberField()"
                            >
                                <v-icon>
                                    mdi-close-circle
                                </v-icon>
                            </v-btn>
                        </template>
                    </v-text-field>
                </v-col>
            </v-row>
            <v-row
                justify="center"
            >
                <v-col 
                    xs="12"
                    md="8"  
                >
                    <v-checkbox
                        v-model="rememberMe"
                        :label="$t('rememberMe')"
                        required 
                    />
                </v-col>
            </v-row>
            <v-row
                justify="center"
            >
                <v-col
                    xs="12"
                    md="8"  
                    class=" ma-0 pa-0 mx-1 mt-5"
                >
                    <v-btn  
                        color="green"
                        class="btnWithRoundedCorner btnHeight42px white--text"
                        tab-index="2"
                        elevation="1"                        
                        block
                        :small="$vuetify.breakpoint.xs"
                        :loading="btnLoading"
                        @click="GetData"
                    >
                        {{ $t('search') }}
                    </v-btn>
                </v-col>
            </v-row>
        </v-form>
    </v-container>
</template>

<script>
import { mapActions } from 'vuex'

export default {
    name:'GetDataForEntrance',
    data(){
        return{
            valid: true,
            btnLoading: false,
            registrationNumber: '',
            cvrNumber: null,
            exemptionBuffer: 30,
            rememberMe: false
        }
    },    
    watch: {
        registrationNumber(value){
            if(this.rememberMe){
                this.registrationNumber = value.replace(/[^a-zA-Z0-9]/g, '')
                this.updateRegistrationNumber(this.registrationNumber)
            }
        },
        cvrNumber(value){
            if(this.rememberMe){
                this.cvrNumber = value.replace(/[^0-9-]/g, '')
                this.SetCVRNumber({
                    cvrNumber: this.cvrNumber
                })
            }
        },
        rememberMe(value){
            if(!value){
                this.SetRememberMe({ 
                    rememberMe: false
                })
                this.SetRegistrationNumber({ 
                    registrationNumber: null
                })
                this.SetCVRNumber({
                    cvrNumber: null
                })
            }else if(value){
                this.SetRememberMe({ 
                    rememberMe: true
                })
                this.SetRegistrationNumber({ 
                    registrationNumber: this.registrationNumber.toUpperCase()
                })
                this.SetCVRNumber({
                    cvrNumber: this.cvrNumber
                })
            }
        }
    },
    mounted(){     
        this.GetRememberMe().then(result => {
            this.rememberMe = result
            if(this.rememberMe){
                this.GetRegistrationNumber().then(result => this.registrationNumber = result)
                this.GetCVRNumber().then(result => this.cvrNumber = result)
            }
        })
        this.GetExemptionBuffer().then(result => this.exemptionBuffer = result.buffer)
        this.$refs.form.resetValidation()
        this.$refs['registrationInput'].$refs.input.focus()
    }, 
    methods:{
        updateRegistrationNumber(){
            if(this.rememberMe)
            {
                this.SetRegistrationNumber({ 
                    registrationNumber: this.registrationNumber.toUpperCase()
                })
                this.SetCVRNumber({
                    cvrNumber: this.cvrNumber
                })   
            }
        },
        clearRegistrationNumberField(){
            this.registrationNumber = null
            this.SetRegistrationNumber({ 
                registrationNumber: null
            })
            this.$refs.form.resetValidation()
            this.$refs['registrationInput'].$refs.input.focus()

        },
        clearCVRNumberField(){
            this.cvrNumber = null
            this.SetCVRNumber({
                cvrNumber: null
            })

            this.$refs.form.resetValidation()
            this.$refs['cvrNumberInput'].$refs.input.focus()
        },
        GetData(){
            if(this.$refs.form.validate())
            {
                this.btnLoading = true

                this.FetchRegistrationInformation({
                    registrationNumber: this.registrationNumber,
                    cvrNumber: this.cvrNumber && this.cvrNumber.includes('-') ? this.cvrNumber.replace('-', '') : this.cvrNumber
                }).then(result => {
                    this.btnLoading = false
                    if(result){
                        if(result.code === 'OK')
                            this.$router.push({name:'RegistrationInformation'})
                        else if(result.code === 'EXIST')
                            this.$router.push({name:'400', params: {textLine1: this.$t('exemption-already-exists-line1'), textLine2: this.$t('exemption-already-exists-line2', {regnr: this.registrationNumber.toUpperCase(), cvr: this.cvrNumber}), error: false}})
                        else if(result.code === 'NOTFOUND')
                            this.$router.push({name:'400', params: {textLine1: this.$t('exemption-error-notfound-line1', {buffer: this.exemptionBuffer}), 
                                textLine2: this.$t('exemption-error-notfound-line2', {regnr: this.registrationNumber.toUpperCase(), cvr: this.cvrNumber}), error: true}})
                        else if(result.code === 'ERROR')
                            this.$router.push({name:'400', params: {textLine1: this.$t('exemption-error-notfound-line1', {buffer: this.exemptionBuffer}), 
                                textLine2: this.$t('exemption-error-notfound-line2', {regnr: this.registrationNumber.toUpperCase(), cvr: this.cvrNumber}), error: true}})
                    }else{
                        this.$router.push({name:'400', params: {textLine1: this.$t('exemption-error-notfound-line1', {buffer: this.exemptionBuffer}), 
                            textLine2: this.$t('exemption-error-notfound-line2', {regnr: this.registrationNumber.toUpperCase(), cvr: this.cvrNumber}), error: true}})
                    }
                })
            }            
        },
        ...mapActions({
            SetRegistrationNumber: 'SetRegistrationNumber',
            SetCVRNumber: 'SetCVRNumber',
            FetchRegistrationInformation: 'FetchRegistrationInformation',
            GetRegistrationNumber: 'GetRegistrationNumber',
            GetCVRNumber: 'GetCVRNumber',
            GetExemptionBuffer: 'GetExemptionBuffer',
            SetRememberMe: 'SetRememberMe',
            GetRememberMe: 'GetRememberMe'
        })
    }
}
</script>

<style scoped>

.container{
    margin-top:60px;
}

</style>
<style>
.registrationNumber input {
  text-transform: uppercase;
}
</style>